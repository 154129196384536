
  export default {
          'Abia': [
             "Aba North",
            "Aba South",
             "Arochukwu",
             "Bende",
             "Ikwuano",
             "Isiala Ngwa",
             "Isuikwuato",
              "Obi Ngwa",
              "Ohafia",
              "Osisioma Ngwa",
              "Ugwunagbo",
               "Ukwa",
               "Umu Nneochi",
               "Umuahia",
           ],
        'Adamawa': [
           "Demsa",
           "Fufore",
           "Ganye",
           "Girei",
           "Gombi",
           "Guyuk",
            "Hong",
           "Jada",
           "Lamurde",
          "Madagali",
           "Maiha",
           "Mayo-Belwa",
           "Michika",
           "Mubi North",
           "Mubi South",
            "Numan",
           "Shelleng",
           "Song",
           "Toungo",
           "Yola North",
           "Yola South",
        ],
        'Akwa Ibom': [
            "Abak",
             "Eastern Obolo",
             "Esit-Eket",
             "Essien Udim",
            "Etim-Ekpo",
            "Etinan",
             "Ibeno",
            "Ibesikpo Asutan",
             "Ibiono Ibom",
             "Ika",
             "Ikono",
             "Ikot Abasi",
             "Ikot Ekpene",
              "Ini",
              "Itu",
              "Mbo",
              "Mkpat Enin",
              "Nsit Atai",
              "Nsit Ibom",
              "Nsit Ubium",
              "Obot Akara",
              "Okobo",
              "Onna",
              "Oron",
              "Oruk Anam",
              "Udung Uko",
              "Ukanafun",
             "Uquo-Ibeno",
              "Uruan",
              "Urue-Offong/Oruko",
              "Uyo"

        ],
        'Anambra': [
             "Aghamelu",
             "Aguata",
            "Anambra East",
             "Anambra West",
             "Anaocha",
            "Awka",
             "Ayamelum",
             "Dunukofia",
             "Ekwusigo",
            "Idemili",
             "Ihiala",
             "Njikoka",
             "Nnewi",
            "Ogbaru",
             "Onitsha",
             "Orumba",

        ],
        'Bauchi': [
            "Alkaleri",
            "Bauchi",
             "Bogoro",
             "Damban",
             "Darazo",
             "Dass",
             "Gamawa",
             "Ganjuwa",
              "Giade",
             "Itas/Gadau",
             "Jama are",
             "Katagum",
             "Kirfi",
             "Misau",
             "Ningi",
             "Shira",
              "Toro",
              "Warji",
             "Zaki"
        ],
        'Bayelsa': [
          "Brass",
          "Ekeremor",
          "Kolokuma Opokuma",
          "Nembe",
          "Ogbia",
          "Sagbama",
          "Southern Ijaw",
          "Yenagoa"
        ],
        'Benue': [
             "Ado",
             "Agatu",
            "Apa",
             "Buruku",
             "Gboko",
             "Guma",
            "Gwer",
             "Katsina-Ala",
             "Konshisha",
             "Kwande",
             "Logo",
            "Makurdi",
             "Obi",
             "Ogbadibo",
             "Ohimini",
             "Oju",
             "Okpokwu",
             "Otukpo",
             "Tarka",
             "Ukum",
             "Ushongo",
            "Vandeikya",
        ],
        'Borno': [
             "Abadam",
             "Askira/Uba",
             "Bama",
             "Bayo",
             "Biu",
             "Chibok",
              "Damboa",
              "Dikwa",
              "Gubio",
             "Guzamala",
              "Gwoza",
              "Hawul",
             "Jere",
             "Kaga",
             "Kala/Balge",
             "Konduga",
             "Kukawa",
            "Kwaya Kusar",
             "Mafa",
            "Magumeri",
             "Maiduguri",
             "Marte",
             "Mobbar",
             "Monguno",
            "Ngala",
             "Nganzai",
            "Shani"
        ],
        "Cross River": [
             "Abi",
             "Akamkpa",
             "Akpabuyo",
              "Bakassi",
              "Bekwara",
              "Biase",
              "Boki",
              "Calabar",
              "Etung",
              "Ikom",
              "Obanliku",
              "Obubra",
              "Obudu",
              "Odukpani",
              "Ogoja",
              "Yakuur",
              "Yala"
        ],

        'Delta': [
            "Asaba",
             "Aniocha North",
            "Aniocha South",
            "Bomadi",
            "Burutu",
             "Ethiope East",
            "Ethiope West",
            "Ika North East",
             "Ika South",
             "Isoko",
            "Ndokwa East",
            "Ndokwa West",
             "Okpe",
             "Oshimili North",
            "Oshimili South",
             "Patani",
             "Sapele",
             "Udu",
            "Ughelli",
             "Uvwie",
             "Warri"
        ],

        'Ebonyi': [
            "Abakaliki",
             "Afikpo North",
             "Afikpo South",
             "Ebonyi",
             "Ezza",
             "Ikwo",
             "Ishielu",
             "Ivo",
             "Izzi",
             "Ohaozara",
            "Ohaukwu",
             "Onicha"
        ],
        'Edo': [
            "Akoko-Edo",
            "Auchi",
             "Benin City",
             "Egor",
             "Ekpoma",
            "Esan North East",
             "Fugar",
            "Igueben",
            "Ikpoba-Okha",
            "Irrua",
            "Uromi",
            "Okada",
            "Orhionmwon",
             "Ovia South",
             "Owan",
            "Ubiaja",
             "Uhunmwonde",
        ],

        'Ekiti': [
            "Ado Ekiti",
            "Aiyekire (Gbonyin)",
             "Aramoko",
            "Efon",
            "Emure",
            "Ido-Osi",
            "Ijero",
             "Ikere",
             "Ikole",
             "Ilawe",
            "Ilejemeje",
             "Irepodun/Ifelodun",
             "Ise/Orun",
            "Moba",
            "Omuo",
             "Oye",
        ],
        'Rivers': [
            "Abua/Odual",
            "Ahoada",
            "Akuku Toru",
             "Andoni",
            "Asari-Toru",
            "Bonny",
             "Degema",
             "Eleme",
            "Emohua",
             "Etche",
            "Gokana",
             "Ikwerre",
             "Khana",
             "Mbiama",
             "Nonwa NYSC",
             "Obio-Akpor",
            "Ogba/Egbema/Ndoni",
            "Ogu/Bolo",
            "Okrika",
            "Omuma",
            "Oyigbo",
            "Port-Harcourt",
             "Tai"
        ],
        'Enugu': [
             "Aninri",
            "Awgu",
             "Enugu",
             "Ezeagu",
             "Igbo Eze South",
            "Igbo-Etiti",
            "Igbo-Eze North",
            "Isi-Uzo",
             "Nkanu East",
             "Nkanu West",
             "Nsukka",
             "Oji-River",
             "Udenu",
             "Udi",
            "Uzo-Uwani"
        ],
        'Federal Capital Territory': [
           "Abaji",
           "Apo District",
           "Asokoro",
           "Bwari",
          "Central Business District",
           "Dakibiyu",
          "Dakwo District",
           "Dei-Dei",
           "Duboyi",
           "Durumi",
          "Dutse-Alhaji",
          "Gaduwa",
          "Galadimawa",
           "Garki 1",
           "Garki 2",
           "Gudu",
           "Guzape District",
           "Gwagwa",
           "Gwagwalada",
           "Gwarinpa",
          "Idu Industrial",
           "Jabi",
           "Jahi",
           "Jikwoyi",
           "Jiwa",
           "Kabusa",
          "Kado",
           "Karmo",
           "Karshi",
           "Karu",
           "Katampe",
            "Kaura",
           "Kpeyegyi",
           "Kubwa",
           "Kuchigoro",
           "Kuje",
          "Kurudu",
           "Kwali",
          "Lokogoma",
           "Lugbe District",
           "Mabushi",
            "Maitama",
            "Mararaba",
           "Masaka",
           "Mbora",
           "Mpape",
           "Nyanya",
           "Okanje",
          "Orozo",
          "Pyakasa",
           "Sabo Gida",
           "Utako",
           "Wumba",
           "Wuse",
           "Wuse 2",
          "Wuye",
           "Zuba",

        ],
        'Gombe': [
             "Akko",
            "Balanga",
             "Billiri",
            "Dukku",
            "Funakaye",
             "Gombe",
             "Kaltungo",
             "Kwami",
             "Nafada",
            "Shomgom",
             "Yamaltu/Deba"
        ],
        'Imo': [
            "Aboh-Mbaise",
            "Ahiazu-Mbaise",
            "Ehime-Mbano",
             "Ezinihitte",
            "Ezinihitte Mbaise",
             "Ideato North",
             "Ideato South",
            "Ihitte/Uboma",
            "Ikeduru",
             "Isiala Mbano",
             "Isu",
             "Mbaitoli",
             "Ngor-Okpala",
             "Njaba",
            "Nkwerre",
             "Nwangele",
             "Obowo",
             "Oguta",
             "Ohaji/Egbema",
             "Okigwe",
             "Onuimo",
             "Orlu",
             "Orsu",
             "Oru",
            "Owerri"
        ],
        'Jigawa': [
            "Auyo",
             "Babura",
             "Biriniwa",
             "Buji",
            "Dutse-Jigawa",
             "Gagarawa",
             "Garki",
             "Gumel",
             "Guri",
             "Gwaram",
             "Gwiwa",
             "Hadejia",
             "Jahun",
            "Kafin Hausa",
             "Kaugama",
             "Kazaure",
            "Kiri Kasamma",
            "Kiyawa",
            "Maigatari",
             "Malam Madori",
             "Miga",
             "Ringim",
             "Roni",
             "Sule-Tankarkar",
             "Taura",
             "Yankwashi",

        ],
        'Kaduna': [
             "Birnin-Gwari",
             "Chikun",
             "Giwa",
             "Igabi",
            "Ikara",
             "Jaba",
            "Jema a",
            "Kachia",
             "Kaduna",
             "Kagarko",
             "Kajuru",
            "Kaura-Kaduna",
             "Kauru",
             "Kubau",
             "Kudan",
             "Lere",
             "Makarfi",
             "Sanga",
             "Soba",
            "Zango-Kataf",
             "Zaria",
        ],
        'Kano': [
             "Ajingi",
             "Albasu",
             "Bagwai",
             "Bebeji",
             "Bichi",
             "Bunkure",
             "Dala",
             "Dambatta",
             "Dawakin Kudu",
             "Dawakin Tofa",
             "Doguwa",
             "Fagge",
             "Gabasawa",
             "Garko",
             "Garum Mallam",
             "Garun Mallam",
             "Gaya",
             "Gezawa",
             "Gwale",
             "Gwarzo",
             "Kabo",
            "Kano Municipal",
             "Karaye",
             "Kibiya",
             "Kiru",
             "Kumbotso",
             "Kunchi",
             "Kura",
            "Madobi",
             "Makoda",
             "Minjibir",
             "Nasarawa-Kano",
             "Rano",
             "Rimin Gado",
             "Rogo",
             "Shanono",
            "Sumaila",
             "Takai",
             "Tarauni",
             "Tofa",
            "Tsanyawa",
             "Tudun Wada",
             "Ungogo",
             "Warawa",
            "Wudil"

        ],
        'Katsina': [
            "Bakori",
             "Batagarawa",
             "Batsari",
             "Baure",
             "Bindawa",
             "Charanchi",
             "Dan Musa",
             "Dandume",
             "Danja",
             "Daura",
             "Dutsi",
             "Dutsin-Ma",
             "Faskari",
             "Funtua",
            "Ingawa",
             "Jibia",
             "Kafur",
            "Kaita",
            "Kankara",
             "Kankia",
             "Katsina",
             "Kurfi",
            "Kusada",
             "Mai adua",
             "Malumfashi",
            "Mani",
             "Mashi",
             "Matazu",
            "Musawa",
             "Rimi",
             "Sabuwa",
             "Safana",
             "Sandamu",
             "Zango"
        ],
        'Kebbi': [
             "Aleiro",
             "Arewa-Dandi",
            "Argungu",
             "Augie",
             "Bagudo",
             "Birnin Kebbi",
             "Bunza",
            "Dandi",
            "Fakai",
             "Gwandu",
            "Jega",
             "Kalgo",
            "Koko/Besse",
             "Maiyama",
             "Ngaski",
             "Sakaba",
             "Shanga",
             "Suru",
            "Wasagu/Danko",
             "Yauri",
             "Zuru"

        ],
        'Kogi': [
            "Adavi",
             "Ajaokuta",
             "Ankpa",
             "Bassa",
             "Dekina",
             "Ibaji",
             "Idah",
             "Igala Mela",
            "Igalamela-Odolu",
             "Ijumu",
             "Kabba/Bunu",
             "Kogi",
             "Koton Karfe",
             "Lokoja",
            "Mopa-Muro",
             "Ofu",
            "Ogori/Magongo",
             "Okehi",
             "Okene",
             "Olamaboro",
             "Omala",
             "Yagba East",
             "Yagba West"

        ],
        'Kwara': [
             "Asa",
             "Baruten",
             "Edu",
            "Ekiti-Kwara",
            "Ifelodun-Kwara",
             "Ilorin East",
            "Ilorin South",
            "Ilorin West",
            "Irepodun-Kwara",
             "Isin",
             "Kaiama",
             "Moro",
             "Oke-Ero",
             "Oyun",
             "Pategi"
        ],
        "Lagos": [
            "Abule Egba",
            "Agbara-Igbesan",
             "Agboyi/Ketu",
            "Agege",
             "Ajah",
             "Ataw",
             "Alimosho",
             "Amuwo-Odofin",
             "Badagry",
             "Bariga",
             "Egbe Idimu",
             "Ejigbo",
             "Eko Atlantic",
            "Epe",
             "Festac",
             "Gbagada",
             "Ibeju",
            "Ifako-Ijaiye",
            "Ikeja",
             "Ikorodu",
             "Ikotun/Igando",
             "Ikoyi",
             "Ilashe",
             "Ijesha",
             "Ilupeju",
             "Iyana-Ipaja",
             "Ipaja",
             "Isolo",
             "Kosofe",
             "Lagos Island (Eko)",
             "Lekki",
             "Magodo",
            "Maryland",
            "Mazamaza/Volks",
            "Mile 2",
            "Mile 12",
            "Mushin",
             "Ogba",
             "Ogudu",
             "Ojo",
             "Ojodu",
             "Ojota",
             "Okota",
             "Orile",
             "Oshodi",
             "Shomolu",
             "Surulere",
             "Tarkwa Bay Island",
             "Victoria Island",
             "Yaba"

        ],
        'Nasarawa': [
             "Akwanga",
            "Awe",
             "Doma",
             "Karu-Nasarawa",
            "Keana",
             "Keffi",
             "Kokona",
             "Lafia",
             "Nasarawa",
             "Nasarawa-Eggon",
             "Obi-Nasarawa",
             "Toto"
        ],
        'Niger': [
             "Agaie",
             "Agwara",
            "Bida",
             "Borgu",
             "Bosso",
             "Chanchaga",
             "Edati",
             "Gbako",
             "Gurara",
            "Kontagora",
             "Lapai",
             "Lavun",
             "Magama",
             "Mariga",
             "Mashegu",
             "Minna",
             "Mokwa",
             "Muya",
             "Paikoro",
             "Rafi",
             "Rijau",
             "Shiroro",
             "Suleja",
             "Tafa",
            "Wushishi"
        ],
        'Ogun': [
            "Abeokuta North",
             "Abeokuta South",
            "Ado-Odo/Ota",
            "Ajibandele",
            "Ayetoro",
             "Ewekoro",
             "Ifo",
             "Ijebu",
             "Ijebu Ode",
             "Ikenne",
            "Ilaro",
             "Imeko Afon",
             "Ipokia",
            "Obafemi-Owode",
             "Odeda",
             "Odogbolu",
             "Ogun Waterside",
            "Remo North",
             "Sagamu",
        ],
        'Ondo': [
             "Akungba",
             "Akure",
             "Ese-Odo",
            "Idanre",
             "Ifedore",
             "Iju/Itaogbolu",
            "Ikare Akoko",
             "Ilaje",
             "Ile-Oluji-Okeigbo",
             "Irele",
             "Isua",
             "Odigbo",
             "Oka",
            "Okeagbe",
            "Okeigbo",
            "Okitipupa",
             "Ondo",
             "Ose",
             "Owo"
        ],
        'Osun': [
             "Aiyedade",
             "Aiyedire",
             "Atakumosa East",
             "Atakumosa West",
            "Boluwaduro",
            "Ede",
             "Egbedore",
            "Ife",
             "Ifedayo",
            "Ifelodun-Osun",
            "Ila",
             "Ilesa",
            "Irepodun-Osun",
             "Irewole",
             "Isokan",
             "Iwo",
            "Obokun",
            "Ola-Oluwa",
             "Olorunda-Osun",
             "Oriade",
            "Orolu",
             "Osogbo"
        ],
        'Oyo': [
             "Afijio",
             "Akinyele",
            "Atiba",
             "Atisbo",
            "Ayete",
             "Egbeda",
             "Eruwa",
            "Ibadan",
             "Ido",
            "Igbo Ora",
            "Irepo",
             "Iseyin",
             "Itesiwaju",
             "Iwajowa",
             "Kajola",
            "Lagelu",
             "Ogbomosho North",
            "Ogbomosho South",
             "Ogo Oluwa",
            "Olorunsogo",
            "Oluyole",
             "Ona-Ara",
             "Orelope",
             "Ori Ire",
             "Oyo",
             "Saki East",
             "Saki West",
             "Surulere-Oyo"

        ],
        'Plateau': [
           "Barkin Ladi",
             "Bassa-Plateau",
            "Bokkos",
             "Jos",
            "Kanam",
            "Kanke",
             "Langtang North",
             "Langtang South",
             "Mangu",
             "Mikang",
            "Pankshin",
            "Quaan Pan",
            "Riyom",
            "Shendam",
             "Wase"
        ],
        'Sokoto': [
            "Binji",
             "Bodinga",
             "Dange-Shuni",
            "Gada",
             "Goronyo",
            "Gudu",
             "Gwadabawa",
            "Illela",
             "Isa",
             "Kebbe",
             "Kware",
             "Rabah",
             "Sabon Birni",
             "Shagari",
            "Silame",
             "Sokoto North",
             "Sokoto South",
            "Tambuwal",
             "Tangaza",
             "Tureta",
             "Wamako",
            "Wurno",
             "Yabo"
        ],
        'Taraba': [
             "Ardo-Kola",
             "Bali",
             "Donga",
             "Gashaka",
             "Gassol",
             "Ibi",
             "Jalingo",
             "Karim-Lamido",
             "Kurmi",
            "Lau",
             "Sardauna",
             "Takum",
             "Ussa",
             "Wukari",
            "Yorro",
             "Zing"
        ],
        'Yobe': [
            "Bade",
            "Bursari",
             "Damaturu",
             "Fika",
             "Fune",
             "Geidam",
            "Gujba",
             "Gulani",
             "Jakusko",
             "Karasuwa",
             "Machina",
             "Nangere",
            "Nguru",
             "Potiskum",
             "Tarmua",
             "Yunusari",
            "Yusufari"
        ],
        'Zamfara': [
             "Anka",
             "Bakura",
             "Birnin Magaji",
             "Bukkuyum",
             "Bungudu",
             "Gummi",
             "Gusau",
            "Kaura Namoda",
            "Maradun",
             "Maru",
             "Shinkafi",
             "Talata Mafara",
             "Tsafe",
            "Zurmi"
      ]
  };
