var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden left-side lg:tw-flex",attrs:{"sm":"12","lg":"5"}},[_c('OnboardingLeft')],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 img-logo",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/bluelogo.png"),"alt":"logo"}}),_c('p',{staticClass:"welcome tw-mt-0"},[_vm._v("Brand Identity")]),_c('p',{staticClass:"admin tw-mt-2"},[_vm._v(" Tell us a few things about your company. ")]),_c('v-row',{staticClass:"upload-div"},[_c('v-col',{attrs:{"lg":"6","sm":"6"}},[_c('p',{staticClass:"upload-div-text"},[_vm._v("Company Logo")]),_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.color,"indeterminate":""}}):_vm._e(),(_vm.recoveredImg)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.recoveredImg,"alt":""}}):(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("../assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})]),_c('v-col',{attrs:{"lg":"6","sm":"6"}},[_c('p',[_vm._v("Company Brand Color")]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"color-picker",style:({background: ("" + _vm.color)}),on:{"click":function($event){$event.preventDefault();return _vm.toggleModal.apply(null, arguments)}}},'div',attrs,false),on))]}}])},[_c('label',[_vm._v("Light colors are not advisable")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-md-1",attrs:{"lg":"12","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Company's Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Company Website")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"roadpadi.com","solo":"","hide-details":"","type":"url","required":""},model:{value:(_vm.companyWebsite),callback:function ($$v) {_vm.companyWebsite=$$v},expression:"companyWebsite"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('v-col',{staticClass:"mt-md-6",attrs:{"lg":"12","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Vision Statement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Vision Statement")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-textarea',{attrs:{"placeholder":"What is the vision of your company?","solo":"","hide-details":"","type":"text","required":"","height":"99"},model:{value:(_vm.visionStatement),callback:function ($$v) {_vm.visionStatement=$$v},expression:"visionStatement"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"btn-div"},[_c('button',{staticClass:"btn-div-text",attrs:{"disabled":!_vm.formIsValid},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_c('span',[_vm._v("Address")]),_c('img',{staticClass:"btn-div-img",attrs:{"src":require("../assets/arrow_forward.svg"),"alt":""}})])])],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog},on:{"close":_vm.toggleModal,"changeTitle":function($event){return _vm.ChangeT($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }