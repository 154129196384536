import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state = {
    transaction:[
        {
            type:'debit',
            amount: 3000,
            balance: 7000,
            description: 'charity',
            referenceId : '5768E7DJ98',
            date: '12th March, 2022',
            time: '4:00pm'
        },
        {
            type:'credit',
            amount: '3000',
            balance: '7000',
            description: 'charity',
            referenceId : '5768E7DJ98',
            date: '19th March, 2022',
            time: '7:00pm'
        }, {
            type:'debit',
            amount: '90,000',
            balance: '700,000',
            description: 'charity',
            referenceId : '5768E7DJ98',
            date: '10th Feb, 2022',
            time: '4:20pm'
        }, {
            type:'credit',
            amount: '3000',
            balance: '7000',
            description: 'Salary',
            referenceId : '5768E7DJ98',
            date: '12th June, 2022',
            time: '2:00pm'
        }, {
            type:'debit',
            amount: '30,000',
            balance: '7000',
            description: 'salary',
            referenceId : '5768E7DJ98',
            date: '12th july, 2022',
            time: '4:00pm'
        }, {
            type:'debit',
            amount: '3000',
            balance: '7000',
            description: 'food',
            referenceId : '5768E7DJ98',
            date: '12th March, 2022',
            time: '4:23am'
        }, {
            type:'credit',
            amount: '13,000',
            balance: '93,000',
            description: 'charity',
            referenceId : '5768E7DJ98',
            date: '12th May, 2022',
            time: '2:00am'
        },
    ]



};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
