var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center",staticStyle:{"background":"linear-gradient(135.05deg, rgba(240, 247, 254, 0.69) 41.6%, #FEF5F0 73.72%)"}},[_c('v-col',{staticClass:"left-side lg:tw-flex",attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"left-side-body",attrs:{"sm":"12"}},[_c('img',{staticStyle:{"height":"40px","width":"186px","cursor":"pointer"},attrs:{"src":"https://res.cloudinary.com/myroadpadi/image/upload/v1703142128/production_asset/whitelogo_z2togt.png","alt":"logo"}}),_c('label',{staticClass:"buddy"},[_vm._v("Hey Buddy!")]),_c('h6',{staticClass:"welcome tw-mt-5"},[_vm._v("Welcome Back.")]),_c('p',{staticClass:"admin tw-mt-5"},[_vm._v("Transport Company Admin Board")])])],1),_c('v-col',{staticClass:"right-side tw-flex tw-items-start lg:tw-items-center",attrs:{"sm":"12","lg":"5"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 tw-cursor-pointer",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":"https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png","alt":"logo"}}),_c('h6',{staticClass:"welcome tw-mt-0"},[_vm._v("Welcome Back!")]),_c('p',{staticClass:"admin tw-mt-1"},[_vm._v("Transport Company Admin Board")]),_c('h6',{staticClass:"signup-header mb-1 mb-md-5"},[_vm._v("Sign In")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.loginTransportCompany)}}},[_c('ValidationProvider',{attrs:{"name":"Email Or Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{ref:"emailInput",attrs:{"placeholder":"Email Or PhoneNumber","data-testid":"email-input","id":"emailId","solo":"","hide-details":"","type":"text","autofocus":"","required":""},model:{value:(_vm.loginData.username),callback:function ($$v) {_vm.$set(_vm.loginData, "username", $$v)},expression:"loginData.username"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-1",class:classes},[_c('v-text-field',{ref:"passwordInput",attrs:{"data-testid":"password-input","hide-details":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'text' : 'password',"solo":"","placeholder":"Password","name":"password","id":"password"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.loginData.password),callback:function ($$v) {_vm.$set(_vm.loginData, "password", $$v)},expression:"loginData.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"tw-pt-4 tw-hidden md:tw-flex"},[_c('h6',{staticClass:" tw-flex already  tw-items-center tw-justify-start"},[_vm._v(" Forget Password? "),_c('router-link',{staticStyle:{"color":"#004AAD","cursor":"pointer"},attrs:{"to":{name: 'TransportCompanyResetPassword'}}},[_vm._v(" Click Here")])],1)]),_c('base-button',{staticClass:"mt-4",attrs:{"id":"","width":"100%","button-text":"Log In","data-testid":"login-button","loading":_vm.loading,"type":"submit"}})],1)]}}])}),_c('div',{attrs:{"data-testid":"value-input"},on:{"click":_vm.logValues}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }