<template>
  <div class="tw-flex tw-w-full tw-h-screen tw-justify-center tw-items-center" style="background: linear-gradient(135.05deg, rgba(240, 247, 254, 0.69) 41.6%, #FEF5F0 73.72%);">
    <v-col sm="12" lg="7" class="tw-hidden left-side lg:tw-flex">
      <v-col sm="12" class="left-side-body">
        <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1703142128/production_asset/whitelogo_z2togt.png" alt="logo" style="height: 40px; width: 186px; cursor: pointer" @click="goHome">
        <label class="buddy">Hey Buddy!</label>
        <h6 class="welcome tw-mt-5">Forgotten Password</h6>
        <p class="admin tw-mt-5">Transport Company Admin Board</p>
      </v-col>
    </v-col>
    <v-col sm="12" lg="5" class="right-side tw-flex lg:tw-items-center tw-items-start">
      <v-col sm="12" class="right-side-body">
        <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 tw-cursor-pointer" @click="goHome">
        <h6 class="welcome tw-mt-0">Forgotten Password</h6>
        <p class="admin tw-mt-1">Please enter your email address or phone number</p>

        <h6 class="signup-header mb-1 mb-md-5">Password Recovery</h6>
        <p class="welcome-big">
          Please enter your email address or phone number. <br> You will recieve a link to create a new password
        </p>

        <form @submit.prevent="forgetPassword">
          <ValidationProvider name="Company's Email" rules="required" v-slot="{ classes, errors }">
            <div class="tw-pt-4 mb-2" :class="classes">
              <v-text-field placeholder="Email Or Phone Number" solo hide-details type="text" v-model="emailOrPhoneNumber" required></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <base-button class="mt-4" width="100%" button-text="Send" style="text-transform: none;" :loading="loading" type="submit"></base-button>
        </form>

        
        
      </v-col>
    </v-col>

    <modal :dialog="dialog" title="Well Done" description="Password Reset" additional-text="A password reset link has been sent to your email" @close="toggleModal" />
  </div>
</template>

<script>
import BaseButton from "../components/reuseables/BaseButton";
import Modal from "../components/reuseables/Modal";
import check from '../assets/check_one.svg'
import { resetPassword } from '../services/api/AuthApiService'

export default {
  name: "TransportCompanyRegisterView",
  components: {Modal, BaseButton},
  data(){
    return{
      title: "Oops! \n" +
          "Forgotten password?",
      description: "Don’t worry, we will help you recover it.",
      value : false,
      loading: false,
      confirmValue : false,
      dialog : false,
      check,
      emailOrPhoneNumber: ''
    }
  },
  computed: {
    formIsvalid() {
      return this.emailOrPhoneNumber !== ''
    }
  },
  methods:{
    toggleModal(){
      this.dialog = !this.dialog
    },
    goHome(){
      window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,'_self')
    },
    async forgetPassword() {
      let data = {
          emailOrPhoneNumber:  this.emailOrPhoneNumber
      }
      this.loading = true
      try {
        const response = await resetPassword(data)
        console.log(response)
        this.loading = false
        this.$displaySnackbar({
          message: response.data.details,
          success: true
        })
        this.emailOrPhoneNumber = ""

        this.dialog = true
      } catch (error) {
        console.log(error.response);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false
        })
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004AAD;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
}
.desc{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(117, 115, 115, 0.5);
}


.left-side{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url('../assets/sign-upbg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0!important;

  @media screen and (max-width: 1024px){
    display: none;
  }
}
.left-side-body{
  display: flex;
  width: 100%;
  background: rgba(0, 74, 173, 0.51);
  flex-direction: column;
  padding:5rem 7rem;
  justify-content: center;
}

.right-side{
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0!important;
  background: white;

}

.right-side-body{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding:7rem;
  justify-content: center;
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}

.welcome-big {
  color: #4F4F4F;
  font-family: "Inter", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .welcome-big {
    display: none;
  }
}

@media screen and (min-width: 1024px){
  .right-side-body > img {
    display: none;
  }

  .right-side-body .welcome{
    display: none;
  }
  .right-side-body .admin{
    display: none;
  }
}

.right-side-body .welcome{
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
}

.right-side-body .admin{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4F4F4F;
  margin-left: 0;
}

.title{
  font-family: "Inter",serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004AAD;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buddy{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;

  @media screen and (max-width: 1024px) {
    margin-top: 35px !important;
  }
}
.admin{
  font-family: '"Inter"',serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-left: 0.8rem;
}


.signup-header{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #004AAD;
}

.terms{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4F4F4F;
}

.already{
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #4F4F4F !important;
}
</style>