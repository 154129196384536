<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style="background-color: #004AAD; width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center tw-items-center tw-self-end tw--mb-5 tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>

      <div
        class=" tw-flex card tw-items-center tw-justify-center tw-flex-col py-4"
        style="min-height: 384px"
      > 
        <h6 class="title text-uppercase tw-py-5">Color Picker</h6>

          <v-color-picker width="800" hide-inputs hide-canvas show-swatches :swatches="swatches"
                          swatches-max-height="200" v-model="color"></v-color-picker>
          
          <base-button class="mt-4" width="auto" button-text="Proceed" @click="passEvent"></base-button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/check_one.svg";
import BaseButton from '../../components/reuseables/BaseButton.vue'
export default {
  name: "Modal",
  components: {
    BaseButton
  },
  props: {
    dialog: Boolean,
    icon: [String],
    title: [String],
    description: [String],
    additionalText: [String],
    userName: [String],
    bottomText: [String],

  },
  data: () => ({
      types: ['hex', 'hexa', 'rgba', 'hsla', 'hsva'],
      type: 'hex',
      hex: '#FF00FF',
      hexa: '#FF00FFFF',
      rgba: { r: 255, g: 0, b: 255, a: 1 },
      hsla: { h: 300, s: 1, l: 0.5, a: 1 },
      hsva: { h: 300, s: 1, v: 1, a: 1 },
      check,
    swatches: [
        [
          '#4679A8',
          '#004AAD',
          '#0007AD',
          '#001C80',
          '#0E0E33',
          '#5D7E8C'
        ],
        [
          '#55CCCC',
          '#00ADAD',
          '#46A8A8',
          '#007880',
          '#1A5F5F',
          '#5D7E8C'
        ],
        [
          '#4679A8',
          '#397DBB',
          '#005280',
          '#1A425F',
          '#0E2633',
          '#5D628C'
        ],
        [
          '#BB50AA',
          '#8A00AD',
          '#570080',
          '#411A5F',
          '#320E33',
          '#8B5D8C'
        ],
      ["#C64D8E","#CA64C0","#BB06A9","#800073","#A84698","#5E1A5F"],
      ["#A84658","#AA0668","#80004D","#5F1A37","#330E17","#8C5D5D"],
      ["#A87546","#BC015B","#AD0000","#800000","#5F1A1A","#8C5D5D"],
      ["#AD9C00","#AD7D00","#806400","#5F501A","#32330E","#A89E46"],
      ["#89A846","#76AD00","#768000","#495F1A","#0E3311","#8C8A5D"],
      ["#46A879","#23AD00","#038000","#1C5F1A","#0E3311","#698C5D"]
        ],


    }),

    computed: {
      color: {
        get () {
          return this[this.type]
        },
        set (v) {
          this[this.type] = v
        },
      },
      showColor () {
        if (typeof this.color === 'string') return this.color

        return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2))
          return color
        }, {}), null, 2)
      },
    },
  methods:{
    passEvent() {
      this.$emit('changeTitle',this.showColor)
    }

  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
}

::v-deep .v-dialog {
  width: 600px;
}
.card {
  width: 100%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  // color: #004aad;
  color: #4F4F4F;

}
.desc {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.v-color-picker {
  align-self: center;
}

::v-deep .v-color-picker__controls {
  display: none;
}
</style>
