
export default {
    SET_COMPANY_DATA: (state, payload) => {
        state.companyData = payload;

    },

    SET_LOADING: (state,payload) =>{
        state.loading = payload
    }
};
