
export default {
    SET_HIRING_CRITERIA_DATA: (state, payload) => {
        state.hiringCriteriaData = payload;
    },

    SET_CURRENT_COMPONENT:(state, payload) =>{
        state.currentComponent = payload
    }


};
