<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white;">
    <v-col sm="12" lg="5" class="tw-hidden left-side lg:tw-flex">

      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="7" class="right-side">
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/bluelogo.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Brand Identity</p>
        <p class="admin tw-mt-2">
          Tell us a few things about your company.
        </p>

        <v-row class="upload-div">
          <v-col lg="6" sm="6">
            <p class="upload-div-text">Company Logo</p>
              <button class="button-logo-input" type="button" @click="onLogoFile()" :class="image ? 'border-0' : ''">
                <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;" v-if="logoImageLoading"></v-progress-circular>
                <img :src="recoveredImg" alt="" class="button-logo-img" v-if="recoveredImg">
                <img :src="image" alt="" class="button-logo-img" v-else-if="logoImage">
                <img src="../assets/camera.png" alt="" class="button-logo-input-img" v-else>
              </button>
            <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
            
          </v-col>
          <v-col lg="6" sm="6">
            <p>Company Brand Color</p>
            <v-tooltip bottom :color="color">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs"
                     v-on="on" class="color-picker"  @click.prevent="toggleModal"  :style="{background: `${color}`}"></div>
              </template>
              <label>Light colors are not advisable</label>
            </v-tooltip>

          </v-col>

        </v-row>
        <v-row>
          <v-col lg="12" sm="12" cols="12" class="mb-md-1">
             <ValidationProvider name="Company's Website" rules="required" v-slot="{ classes, errors }">
               <label  class="label">Company Website</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-text-field  placeholder="roadpadi.com" solo hide-details type="url" v-model="companyWebsite"  required></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

          </v-col>

          <v-col lg="12" sm="12" cols="12" class="mt-md-6">
            <ValidationProvider name="Vision Statement" rules="required" v-slot="{ classes, errors }">
                <label  class="label">Vision Statement</label>

                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-textarea  placeholder="What is the vision of your company?" solo hide-details type="text" v-model="visionStatement"  required height="99"></v-textarea>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

          </v-col>
        </v-row>

        <div class="btn-div">
          <button class="btn-div-text" :disabled="!formIsValid" @click.prevent="saveData">
            <span>Address</span>
            <img src="../assets/arrow_forward.svg" alt="" class="btn-div-img">  
          </button>
        </div>
      </v-col>

    </v-col>
    <modal
      :dialog="dialog"
      @close="toggleModal"
      @changeTitle="ChangeT($event)"

    />

  </div>
</template>

<script>
  import OnboardingLeft from '@/components/reuseables/OnboardingLeft'
  import Modal from '@/components/reuseables/ColorPickerModal'
  import {uploadToCloudinary} from "../services/api/APIService";
  import VueJwtDecode from "vue-jwt-decode";

  export default {
    components: {
      OnboardingLeft,
      Modal
    },
    data() {
      return {
        companyWebsite : "",
        visionStatement: "",
        color: '#004AAD',
        logoImage: '',
        logoImageUrl: '',
        logoImageLoading: false,
        recoveredImg: '',
        image: '',
        dialog : false,
      }
    },
    computed: {
      formIsValid() {
        return this.logoImage !== '' && this.companyWebsite !== '' && this.visionStatement !== '' || this.recoveredImg !== '' && this.companyName !== '' && this.companywebsite !== '' && this.visionStatement !== ''

      },
      preset() {
        return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      },
      data() {
        return JSON.parse(sessionStorage.getItem('data'))
      }
    },
    methods:{
      fillFields(){
        this.image = ""
        if (this.data) {
          this.companyWebsite = this.data.website
          this.recoveredImg = this.data.companyLogo
          this.color = this.data.companyColor
          this.visionStatement = this.data.visionStatement
        }
      },
      ChangeT(title){
        this.title = title;
        this.color = title
        this.dialog = false
      },
      onLogoFile() {
        this.$refs.logoImage.click();
      },
      async onLogoImagePicked(event){
        const files = event.target.files[0]
        try {
          this.logoImageLoading = true
          const fileReader =  new FileReader()
          fileReader.addEventListener('load', async () => {
            this.recoveredImg = ""
            this.image = fileReader.result
            this.logoImage = files.name
            let newData = {
              file: this.image,
              upload_preset: this.preset
            }
            const response = await uploadToCloudinary(newData)
            console.log(response)
            this.logoImageUrl = response.data.url
            this.logoImageLoading = false
          })
          fileReader.readAsDataURL(files)
        } catch (err) {
          console.log(err.response)
        }

      },
      toggleModal(){
        this.dialog = !this.dialog
      },
      saveData() {
        if(!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(this.companyWebsite)){
           return this.$displaySnackbar({
            message: "Please Enter A Valid Website",
            success: false
          })
        }

        if (this.data) {
          this.data.website = this.companyWebsite
          this.data.companyLogo = this.logoImageUrl || this.recoveredImg
          this.data.companyColor = this.color
          this.data.visionStatement = this.visionStatement
          sessionStorage.setItem('data', JSON.stringify(this.data));
          this.$router.replace({ name: 'OnboardingAddress' })

        } else {
          let data = {
            website: this.companyWebsite,
            companyLogo: this.logoImageUrl || this.recoveredImg,
            companyColor: this.color,
            visionStatement: this.visionStatement,
          }
          sessionStorage.setItem('data', JSON.stringify(data));
          this.$router.replace({ name: 'OnboardingAddress' })
        }
        
      }
    },
    mounted(){
      this.fillFields()
    },
    created() {
      let token = this.$route.query.access_token
      if (token){
        let decodedToken = VueJwtDecode.decode(token);
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const payLoad = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );
        sessionStorage.setItem("userData", JSON.stringify(decodedToken));
        sessionStorage.setItem("userToken", JSON.stringify(token));
        return payLoad;
      }
    }
  }
</script>

<style scoped lang="scss">
.v-progress-circular {
  margin: 1rem;
}
button:disabled,
button[disabled]{
  opacity: 0.7;
}

.left-side{
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}

.right-side-body{
  width: 100%;
  padding:7rem;
  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.3rem;
  }
}

.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .welcome {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #004AAD;
}

.right-side-body .admin {
  width: 255px;
  height: 24px;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;
  margin-top: 17px;
}

.upload-div {
  margin-top: 37px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}

.welcome{
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  
  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}

.upload-div .upload-div-text {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
  margin-bottom: 20px;
}

.color-picker {
  height: 84px;
  width: 84px;
  padding: 20px 05px;
  // background: blue;
  border-radius: 100%;
  cursor: pointer;
}

.button-logo-input {
  display:flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.label {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}

.btn-div{
  display: flex;
  justify-content: flex-end;
  margin-top: 52px;
}

.btn-div .btn-div-text {
  border-radius: 10px;
  background: #004AAD;
  color: white;
  text-align: right;
  padding: 10px 30px;
  width: 161px;
  justify-content: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.btn-div .btn-div-text .btn-div-img {
  margin-left: 5px;
}

</style>