
export default {
    addSeat({ commit }, payload) {
        commit("ADD_SEAT", payload);
    },
    removeSeat({ commit }, payload) {
        commit("REMOVE_SEAT", payload);
    },
    setOneWayBookingMinutesTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_MINUTES_TIMER',payload)
    },

    setOneWayBookingShowTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_SHOW_TIMER',payload)
    },
    clearSeats({commit}){
        commit("CLEAR_SEATS")
    },
    getTotalAmount({commit},value){
        commit("GET_TOTAL_AMOUNT",value)
    },
    updateCurrentSeats({commit},payload){
        commit("UPDATE_CURRENT_SEATS",payload)
    }
};