export default {
    setHiringCriteriaData({ commit }, payload) {
        commit("SET_HIRING_CRITERIA_DATA", payload);
    },
    setSelectedCriteria({ commit }, payload) {
        commit("SET_SELECTED_CRITERIA", payload);
    },
    setHiringRouteData({commit}, payload){
        commit("SET_HIRING_ROUTE_DATA", payload);
    }
};