import Vue from "vue";
import Vuex from "vuex";
import snackBar from "./snackBar";
import dashboardComponent from "./dashboardComponent";
import terminalComponent from "./terminalComponent";
import companyProfile from "./companyProfile";
import managerComponent from "./managerComponent";
import bookingAdminComponent from "./bookingAdminComponent";
import bookingComponent from "./bookingComponent";
import pilotComponent from "./pilotComponent";
import fleetComponent from "./fleetComponent";
import tripComponent from "./tripComponent";
import routeComponent from "./routeComponent";
import webBookingComponent from "./webBookingComponent";
import terminalManagerTerminalComponent from "./terminalManagerTerminalComponent";
import terminalManagerBookingAdminComponent from "./terminalManagerBookingAdminComponent";
import terminalManagerBookingComponent from "./terminalManagerBookingComponent";
import terminalManagerFleetComponent from "./terminalManagerFleetComponent";
import terminalManagerManagerComponent from "./terminalManagerManagerComponent";
import terminalManagerPilotComponent from "./terminalManagerPilotComponent";
import terminalManagerTripComponent from "./terminalManagerTripComponent";
import guideData from "./guideData";
import selectedSeat from "./selectedSeat";
import terminalManagerRouteComponent from "./terminalManagerRouteComponent";
import manifestComponent from "@/store/manifestComponent";
import returnTripReturnSeatsSelection from "@/store/returnTripReturnSeatsSelection";
import seatsSelection from "@/store/seatsSelection";
import tripAndTravellerDetails from "./tripAndTravellerDetails";
import walletStore from "@/store/walletStore";

import terminalDashboardComponent from "./terminalDashboardComponent";
import leasingComponent from "@/store/leasingComponent";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackBar,
    webBookingComponent,
    dashboardComponent,
    terminalComponent,
    managerComponent,
    companyProfile,
    bookingAdminComponent,
    bookingComponent,
    pilotComponent,
    fleetComponent,
    routeComponent,
    tripComponent,
    terminalDashboardComponent,
    terminalManagerTerminalComponent,
    terminalManagerBookingAdminComponent,
    terminalManagerBookingComponent,
    terminalManagerFleetComponent,
    terminalManagerManagerComponent,
    terminalManagerPilotComponent,
    terminalManagerTripComponent,
    guideData,
    selectedSeat,
    terminalManagerRouteComponent,
    manifestComponent,
    returnTripReturnSeatsSelection,
    seatsSelection,
    tripAndTravellerDetails,
    walletStore,
    leasingComponent
  },
});
