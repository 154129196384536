import {isTokenExpired} from "./AuthApiService";

export default function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('userToken'));

    if (typeof user === 'object' && user !== undefined && user !== null) {
        if (isTokenExpired(user.access_token)) {
            sessionStorage.removeItem('userToken');
            window.location.href = "/login";
        }
        else {
            return {Authorization: `Bearer ${user.access_token}`};
        }
    }
    else if (user){
        if (isTokenExpired(user)) {
            sessionStorage.removeItem('userToken');
            window.location.href = "/login";
        }
        else {
            return {Authorization: `Bearer ${user}`}
        }
    }
    else {
        return {};
    }


}
