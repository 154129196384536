
export default {
    SET_HIRING_CRITERIA_DATA: (state, payload) => {
        state.hiringCriteriaData = payload
    },
    SET_SELECTED_CRITERIA: (state, payload) => {
        state.selectedCriteria = payload
    },
    SET_HIRING_ROUTE_DATA: (state, payload) =>{
        state.hiringRouteData = payload
    }
};
