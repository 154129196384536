import {getCompanyData} from "@/services/api/APIService";

export default {
   async setCompanyData({ commit }, data) {

        return await getCompanyData(data).then(
            res => {
                commit("SET_COMPANY_DATA", res.data);
                sessionStorage.setItem("companyData", JSON.stringify(res.data))
                return Promise.resolve()},
            error => {

                return Promise.reject(error)
            }
        )

    },

    async setCompanyDataForTraveller({commit}, payload){
     commit("SET_COMPANY_DATA",payload)
    },
    setLoading({commit},payload){
       commit("SET_LOADING",payload)
    }
};